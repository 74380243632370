<template>
  <div>
    <vs-table    v-model="selectedDomain"   :data="domainList">
      <template slot="header">
       <div class="mb-2">
         <vs-input placeholder="search" @keyup.enter="getAccountList" v-model="search"></vs-input>
       </div>
      </template>
      <template slot="thead">
        <vs-th>user name</vs-th>
       <vs-th> domain </vs-th>
       <vs-th>created time </vs-th>
       <vs-th>username </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].user_fullname"> {{tr.username}} </vs-td>
          <vs-td :data="data[indextr].domain">  {{data[indextr].domain}} </vs-td>
         <vs-td :data="data[indextr].created_at"> {{data[indextr].created_at}}</vs-td>
          <vs-td :data="data[indextr].verify_at"> {{data[indextr].user_fullname}}</vs-td>
        </vs-tr>
      </template>
    </vs-table>

   <vs-pagination :total="countPage" v-model="pageNumber"></vs-pagination>
    <pre>{{ selectedDomain }}</pre>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedDomain : null,
      domainList : [],
      search     : '',
      countPage  : 1,
      pageNumber : 1,
      domainId   : ''

    }
  },
  watch : {
    pageNumber () {
      this.getAccountList()
    },
    $route () {
      this.getAccountList()
    }
  },
  methods : {
    getAccountList () {
      this.$http.get(`/admin/email/account/list?page=${this.pageNumber}&search=${this.search}&domain_id=${this.$route.params.id}`).then((result) => {
        this.countPage = result.data.params.last_page
        this.domainList = result.data.params.data
      }).catch((err) => {
        console.log(err)
      })
    }
    // getAccountinfo () {
    //   this.$http.get(`/admin/email/account/get?account_id=${this.selectedDomain.id}`).then((result) => {
    //     console.log(result)
    //   }).catch((err) => {
    //     console.log(err)
    //   })
    // }
   
   
  },
  created () {
    // console.log(this.$route.params.id)
    this.getAccountList()
  }
}
</script>